import React from "react"
import HeadData from "../data/HeadData"
import Footer from "../components/section/Footer";
import HeroBanner from "../components/HeroBanner";
import Header from "../components/section/Header";

const NotFoundPage = () => (
  <>
    <HeadData/>
    <HeroBanner/>
    <Header />
    <div className="w-full flex items-center justify-center py-20">
      <h1>Page not found.</h1>
    </div>
    <Footer />
  </>
)

export default NotFoundPage
